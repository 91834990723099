import { useEffect, useState, useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import "./particles.scss";

const ParticlesBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const ParticlesConfig = useMemo(
    () => ({
      fullScreen: { enable: false },
      background: { color: { value: "#0066ff" } },
      particles: {
        number: { value: 80, density: { enable: true } },
        color: { value: "#f5f5f5" },
        links: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.5,
          width: 1,
        },
        shape: { type: "circle" },
        move: { enable: true, speed: 1.234 },
      },
    }),
    []
  );

  return (
    <div className="background">
      {init && (
        <Particles
          id={"tsparticles-bg"}
          options={ParticlesConfig}
          className="particles"
        />
      )}
    </div>
  );
};

export default ParticlesBackground;
