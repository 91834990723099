import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './css/Landingpage.css';

const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

function Products() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the products.json file from the public folder
        const response = await fetch('/assets/products.json');
        const data = await response.json();

        // Map through the products and create the full image URLs
        const productsWithImages = data.map((product) => {
          const imageUrl = `/assets/Showcase/${product.img}`; // Construct the image URL from the public folder
          return { ...product, imgUrl: imageUrl };
        });

        setProducts(productsWithImages);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section id="homesec nav" className="section px-0">
      <div className="row cards grid justify-content-center">
        {isLoading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Card key={index} className="hidden LP">
              <Skeleton height={262} width={262} className="L3" />
              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Skeleton height={30} width="60%" />
                <Skeleton height={50} width="90%" />
                <Skeleton height={35} width="40%" />
              </Card.Body>
            </Card>
          ))
        ) : (
          products.map((product) => (
            <Card
              key={product.id}
              data-aos="fade-right"
              data-aos-delay={product.delay}
              className="hidden LP"
            >
              <Card.Img
                variant="top"
                src={product.imgUrl}
                height="262px"
                alt="product"
                className="L3"
              />
              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Card.Title>{product.text}</Card.Title>
                <Card.Text>
                  {product.description}
                </Card.Text>
                <HashLink smooth to={product.link} scroll={el => scrollWithOffset(el)}>
                  <Button variant="primary" className="vist-page-button">Visit page</Button>
                </HashLink>
              </Card.Body>
            </Card>
          ))
        )}
      </div>
    </section>
  );
}

export default Products;
