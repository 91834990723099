import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { MdLocationOn, MdOutlineAlternateEmail, MdFax } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import { AiFillPhone } from "react-icons/ai";
import { CgAsterisk } from "react-icons/cg";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import * as formik from "formik";
import * as yup from "yup";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import DOMPurify from "dompurify";
import ParticlesBackground from "../background/particles/particles";
import "./contact.css";

const { Formik } = formik;

const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  inquiry: yup.string().required("Inquiry is required"),
});

const sanitizeInput = (input) => {
  return DOMPurify.sanitize(input);
};

const ContactForm = () => {
  const [status, setStatus] = useState(null);
  const formRef = useRef(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaWarning, setRecaptchaWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setIsLoading(true);
    setRecaptchaWarning(false);
    if (!recaptchaValue) {
      setRecaptchaWarning(true);
      setStatus("failure");
      return;
    }
    const sanitizedValues = {
      email: sanitizeInput(values.email),
      inquiry: sanitizeInput(values.inquiry),
      "g-recaptcha-response": recaptchaValue,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        sanitizedValues,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        () => {
          setStatus("success");
          resetForm();
          setRecaptchaValue(null);
          setIsLoading(false);
        },
        (error) => {
          setStatus("failure");
          console.error("Email failed to send:", error.text);
          setIsLoading(false);
        }
      );
  };

  return (
    <div className="big-container row justify-content-center">
      <Helmet>
        <title>Contact Us - ABC Engineering</title>

        <meta
          name="description"
          content="Get in touch with ABC Engineering. Contact us for inquiries about our data center solutions and services. We're here to help!"
        />
        <meta
          name="keywords"
          content="Contact, ABC Engineering, Data Center Solutions, Inquiries, Support, Location, Address, Contacts, Number, Email, Sales"
        />
        <meta property="og:title" content="Contact" />
        <meta
          property="og:description"
          content="ABC Engineering contact page"
        />
        <meta property="og:image" content="https://abc-eng.com/logo.webp" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://abc-eng.com/contact" />
      </Helmet>
      <ParticlesBackground />
      <div className="col-md-8 col-sm-5 form-border">
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            email: "",
            inquiry: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form
              ref={formRef}
              className="form"
              noValidate
              method="post"
              onSubmit={handleSubmit}
              id="contact-form"
            >
              <h1 className="contact-title">Contact Us</h1>
              {status === "success" && (
                <Alert variant="success">
                  Email delivered, We'll contact you shortly!
                </Alert>
              )}
              {status === "failure" && (
                <Alert variant="danger">Email failed to send!</Alert>
              )}
              {recaptchaWarning && (
                <Alert variant="warning">Please complete the reCAPTCHA.</Alert>
              )}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <CgAsterisk style={{ color: "red" }} />
                  Email address
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicInquiry">
                <Form.Label>
                  {" "}
                  <CgAsterisk style={{ color: "red" }} />
                  Inquiry{" "}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="inquiry"
                  placeholder="What would you like to talk about?"
                  value={values.inquiry}
                  onChange={handleChange}
                  isInvalid={!!errors.inquiry && touched.inquiry}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.inquiry}
                </Form.Control.Feedback>
              </Form.Group>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={setRecaptchaValue}
              />

              <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Sending...
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <div
        className="col-md-6 col-sm-6 map"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <iframe
          title="Location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d862.7589461990483!2d31.3681191!3d30.1217895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14581662f2f96ef9%3A0xd5569a617c213350!2s14%20Ahmed%20Abd%20El-Nabi%2C%20Huckstep%2C%20El%20Nozha%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1672060879606!5m2!1sen!2seg"
          allowFullScreen
          className="map-location"
        ></iframe>
      </div>
      <div className="col-md-6 col-sm-6 ccontacts">
        <h1 className="bq-header">Get in Touch</h1>
        <br />
        <blockquote className="blockquote2">
          <p>
            <a
              className="address"
              rel="noreferrer"
              href="https://goo.gl/maps/dMwJXyRmTzciZSZD9"
              target="_blank"
            >
              <MdLocationOn className="me-3" />
              14 Ahmed Abd El-Nabi, El Nozha <br />
              Cairo - Egypt
            </a>
          </p>
          <p>
            <a className="phone" href="tel:+0226210042">
              <AiFillPhone className="me-3" />
              <MdFax className="me-3" /> +02 26210042
            </a>
          </p>
          <p>
            <a className="mail" href="mailto:sales@abc-eng.org">
              <MdOutlineAlternateEmail className="me-3" />
              sales@abc-eng.org
            </a>
          </p>
          <p>
            <a className="phone" href="tel:+201006111105">
              <FaMobileAlt className="me-3" /> +20 1006111105
            </a>
          </p>
        </blockquote>
      </div>
    </div>
  );
};

export default ContactForm;
