import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import {AiOutlineHdd,AiOutlineSetting,AiOutlineUndo,AiOutlineHistory,AiOutlineDatabase} from "react-icons/ai";
import { Helmet } from "react-helmet";

import ParticlesBackground from '../background/particles/particles';

import "./Services.css";
class Services extends Component {
    render() { 
        return (
            <div className='service-container'>
            <Helmet>
            <title>Services - ABC Engineering</title>
                <meta name="description" content="Explore our wide range of professional services." />
                <meta name="keywords" content="ABC Engineering, Services, Expertise" />
                <meta property="og:title" content="Services" />
                <meta property="og:description" content="ABC Engineering services" />
                <meta property="og:image" content="https://abc-eng.com/logo.webp" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://abc-eng.com/services" />
            </Helmet>
            <ParticlesBackground/>
            <div className='container'>
            <div className='row grid justify-content-center secondary-container'>

                <Accordion defaultActiveKey="0">
                        <section className='service-tab'>
                            <Accordion.Item eventKey="0" >
                            <Accordion.Header className='aheader'><h2><AiOutlineHdd/>Installation Services</h2></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>Batteries</li>
                                        <li>Isolation Transformers</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section className='service-tab'>
                        <Accordion.Item eventKey="1">

                        <Accordion.Header className='aheader'><h2><AiOutlineSetting/>Maintenance</h2></Accordion.Header>
                        <Accordion.Body>
                        <ul>
                            <h5>Scope of work</h5><ul>
                                <li>CRAC units</li> 
                                <li>UPS Systems</li>
                                <li>DC Systems</li>
                                <li>Change spare parts</li>
                                <li>UPS Capacitors change</li>
                            </ul>
                            <li>Corrective Maintenance</li>
                            <li>Preventive Maintenance</li>	
                            <li>Raised Floor Maintenance</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    </section>
                    

                    <section className='service-tab'>
                            <Accordion.Item eventKey="4" >
                            <Accordion.Header className='aheader'><h2><AiOutlineDatabase/>Data Center Design</h2></Accordion.Header>
                                <Accordion.Body>
                                <ul>
                                    <li>Load calculations</li>
                                    <li>Power System design</li>
                                    <li>Cooling System design</li>
                                    <li>Civil work design</li>
                                </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        
                        <section className='service-tab'>
                        <Accordion.Item eventKey="5">

                        <Accordion.Header className='aheader'><h2><AiOutlineHistory/>Turn-Key project Solutions</h2></Accordion.Header>
                        <Accordion.Body>
                        <ul>
                            <li>Power</li>
                            <li>Cooling</li>
                            <li>Civil Work</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    </section>


                    <section className='service-tab'>
                    <Accordion.Item eventKey="3">
                    <Accordion.Header className='aheader'><h2><AiOutlineUndo/>Transfer Service: Uninstall, Transport and Re-install</h2></Accordion.Header>
                    <Accordion.Body>
                    <ul>
                    <li>CRAC</li>
                    <li>UPS</li>
                    <li>DC Systems</li>
                    </ul>
                    </Accordion.Body>
                    </Accordion.Item>
                    </section>
                </Accordion>
            </div>
            </div>
        </div>
        );
    }
}
export default Services;
