import MainNavbar from './components/navbar/Navbar';
import Landingpage from './components/landingpage/Landingpage';
import NotFound from './components/redirects/NotFound';
// eslint-disable-next-line
import WOP from './components/redirects/WOP';
import About from './components/about/About';
import Footer from './components/footer/Footer';
import Services from './components/serv/Services';
import Solutions from './components/solutions/Solutions';
import Contact from './components/contact/contact';
import Projects from './components/projects/projects';

import React, { Component , memo} from 'react';
import { Route , Routes} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

class App extends Component{
  render(){
    return (
      <div>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={`
                      default-src 'self' data:;
                      script-src 'self' https://www.google.com https://www.gstatic.com https://apis.google.com;
                      img-src 'self' data: https://abc-website-admin.firebaseapp.com https://firebasestorage.googleapis.com;
                      media-src 'self' https://firebasestorage.googleapis.com/ https://abc-website-admin.firebaseapp.com;
                      connect-src 'self' https://goo.gl https://api.emailjs.com https://www.google.com https://www.gstatic.com https://identitytoolkit.googleapis.com https://securetoken.googleapis.com https://firebasestorage.googleapis.com https://firestore.googleapis.com;
                      frame-src https://www.google.com https://abc-website-admin.firebaseapp.com https://abc-engineering-demo.vercel.app https://abc-eng.org;
                `}
        ></meta>
      </Helmet>
        <MainNavbar/>
        <div id='main-content'>
            <Routes>
              <Route path='/' element={<Landingpage/>}/>
              <Route path='/home' element={<Landingpage/>}/>
              <Route path='/solutions' element={<Solutions/>}/>
              <Route path='/services' element={<Services/>}/>
              <Route path='/projects' element={<Projects/>}/>
              {/* <Route path='/projects' element={<WOP/>}/> */}
              <Route path='/contact' element={<Contact/>}/>
              <Route path='/about' element={<About/>}/>
              <Route path='*' element={<NotFound/>} />
            </Routes>
        </div>
        <Footer/>
      </div>
  );
  }
}

export default memo(App);
