import { Component } from "react";
import './NotFound.css';

class not_found extends Component {
  render() {
    return (
        <div className='row align-items-center main-container404'>
            <img data-aos="fade-down" data-aos-anchor-placement="top-bottom" src={require("../redirects/404bot.png")} className="img-fluid mx-auto " alt="404-Error"/>
            {/* <a href="https://storyset.com/web"  target="_blank" rel="noopener noreferrer"/> */}
            
        </div>
    );
  }
}
export default not_found;
