import React from 'react';
import { Component } from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { MdLocationOn,MdOutlineAlternateEmail,MdAccessTime,MdFax } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";

import { AiFillPhone } from "react-icons/ai";
import { HashLink } from 'react-router-hash-link';
import "./Footer.css";
const scrollWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -100; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}
class Footer extends Component {
  render() {
    return (
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted FooterCont'>
        <br/>
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                <img height="66px" width="auto" alt="ABC-ENGINEERING" src={require("../../logo.png")}></img>
              </h6>
              <p>
              ABC Engineering S.A.E was established in 2008 by a group of professional engineers with vast experience in data center solutions.
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>SOLUTIONS</h6>
              <p>
                <HashLink smooth to='/solutions#cooling' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  Precision Cooling
                </HashLink>
              </p>
              <p>
              <HashLink smooth to="solutions#ups" scroll={el => scrollWithOffset(el)} className='text-reset'>
                UPS
              </HashLink>

              </p>
              <p>
                <HashLink smooth to='/solutions#it' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  IT Infrastructure
                </HashLink>
              </p>
              <p>
                <HashLink  smooth to='/solutions#raisedfloor' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  Raised Floor
                </HashLink>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>FAQ's</h6>
              <p>
                <HashLink  smooth to='/contact#contact-form' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  Request a quote
                </HashLink>
              </p>
              <p>
                <HashLink  smooth to='/home#customers' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  Customers
                </HashLink>
              </p>
              <p>
                <HashLink  smooth to='/projects' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  Projects
                </HashLink>
              </p>
              <p>
                <HashLink  smooth to='/home#partners' scroll={el => scrollWithOffset(el)} className='text-reset'>
                  Partners
                </HashLink>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <a rel="noreferrer" href='https://goo.gl/maps/dMwJXyRmTzciZSZD9' target="_blank">
                <MdLocationOn className="me-2" />
                14 Ahmed Abd El-Nabi, El Nozha <br/>Cairo - Egypt
                </a>
                </p>
                <p>
                <a href="tel:+0226210042">
                <AiFillPhone className="me-2" /><MdFax className="me-2"/>  +02 26210042
                </a>
              </p>
              <p>
                <a rel="noreferrer" href='https://www.google.com/maps/dir//abc+engineering/@30.09799,31.322107,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x145815df477c1b4f:0x175764ec803c792f!2m2!1d31.345957!2d30.1239978' target="_blank">
                <MdLocationOn className="me-2" />
                174, Gesr Al-Suez St., Heliopolis <br/>Cairo - Egypt<br/>
                </a>
                </p>
                <p>
                <a href="tel:+0221914159">
                <AiFillPhone className="me-2" /><MdFax className="me-2"/>  +02 21914159
                </a>
              </p>
              <p>    
                <a href="mailto:sales@abc-eng.org">          
                <MdOutlineAlternateEmail className="me-2" />
                sales@abc-eng.org
                </a>
              </p>
              <p>
                <a href="tel:+201006111105">
                <FaMobileAlt className="me-2" /> +20 1006111105
                </a>
              </p>
              <p>
                <MdAccessTime className="me-2" /> Sun-Thur: 09.00 - 16.00
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='/home'>
          ABC Engineering S.A.E
        </a>
      </div>
    </MDBFooter>
  )
}
}

export default Footer;