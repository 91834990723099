import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { AiFillPhone } from 'react-icons/ai';
import { MdAccessTime } from 'react-icons/md';
import navData from './NavData.json';

import './Navbar.css';

const MainNavbar = () => {
  const { data } = navData;
  const location = useLocation();
  const [contactTextColor, setContactTextColor] = useState('black');

  useEffect(() => {
    const paths = ['/solutions', '/projects','/services', '/contact'];
    if (paths.includes(location.pathname)) {
      setContactTextColor('white');
    } else {
      setContactTextColor('black');
    }
  }, [location.pathname]);

  const scrollFunction = () => {
    const navbar = document.getElementById('navbar');
    const logo = document.getElementById('logo');

    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      logo.style.height = '75px';
      navbar.style.fontSize = '20px';
      navbar.style.marginTop = '1vh';
    } else if (window.innerWidth <= 768) {
      logo.style.height = '66px';
      navbar.style.fontSize = '25px';
      navbar.style.marginTop = '6vh';
    } else {
      logo.style.height = '90px';
      navbar.style.fontSize = '25px';
      navbar.style.marginTop = '3vh';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollFunction);
    return () => window.removeEventListener('scroll', scrollFunction);
  }, []);

  const handleNavLinkClick = () => {
    const lastSegment = document.URL.split('/').pop() || '/';
    const navElements = document.getElementsByClassName('contacts');
    Array.from(navElements).forEach((nav) => {
      nav.style.color = nav.id === lastSegment ? '#0066ff' : 'black';
    });
  };

  useEffect(() => {
    handleNavLinkClick();
  }, []);

  return (
    <div className="NavCont">
      {data.map((entry, index) => (
        <div className="contacts row" key={index}>
          <div className="col" style={{ textAlign: 'left' , color: contactTextColor }}>
            <MdAccessTime style={{ color: '#0087ca' }} /> {entry.start_day}-{entry.end_day}:{' '}
            {entry.start_hour}-{entry.end_hour}
          </div>
          <div className="col" style={{ textAlign: 'right', color: contactTextColor }}>
            <AiFillPhone style={{ color: '#0087ca' }} /> {entry.phone}
          </div>
        </div>
      ))}
      <Navbar
        className="firstNav"
        fixed="top"
        expand="md"
        id="navbar"
        variant="light"
        style={{
          fontSize: '25px',
          padding: 'none',
          marginLeft: '10px',
          marginRight: '10px',
          borderRadius: '25px',
        }}
      >
        <Container className="row">
          <Navbar.Brand className="col branding" as={Link} to="/home">
            <img id="logo" height="90px" width="auto" src={require('../../logo.webp')} alt="ABC logo"className='nav-logo' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" aria-expanded="false" aria-label="Toggle navigation" className="col" />
          <Navbar.Collapse id="basic-navbar-nav "na>
            <Nav className="col m-auto justify-content-center">
              <Nav.Link className="Mnav" id="home" as={Link} to="/home" onClick={handleNavLinkClick}>
                <h4>HOME</h4>
              </Nav.Link>
              <Nav.Link className="Mnav" id="solutions" as={Link} to="/solutions" onClick={handleNavLinkClick}>
              <h4>SOLUTIONS</h4>
              </Nav.Link>
              <Nav.Link className="Mnav" id="services" as={Link} to="/services" onClick={handleNavLinkClick}>
              <h4>SERVICES</h4>
              </Nav.Link>
              <Nav.Link className="Mnav" id="projects" as={Link} to="/projects" onClick={handleNavLinkClick}>
              <h4>PROJECTS</h4>
              </Nav.Link>
              <Nav.Link className="Mnav" id="about" as={Link} to="/about" onClick={handleNavLinkClick}>
              <h4>ABOUT</h4>
              </Nav.Link>
              <Nav.Link className="Mnav" id="contact" as={Link} to="/contact" onClick={handleNavLinkClick}>
              <h4>CONTACT</h4>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MainNavbar;
