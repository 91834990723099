import { Component } from "react";
import { Helmet } from "react-helmet";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import './About.css';
import './About.scss';
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  mirror: true,
  once: false,
  easing: "ease-in-out",
});
class About extends Component {
  render() {
    return (
        <div className="A1">
            <Helmet>
          <title>About Us - ABC Engineering</title>
          <meta name="description" content="Learn about ABC Engineering's mission, vision, and core values. We aim to be the leading provider of electromechanical services for data centers in Egypt." />
          <meta name="keywords" content="About ABC Engineering, Mission, Vision, Values, Electromechanical services, Data centers, Egypt" />
          <meta property="og:title" content="About" />
          <meta property="og:description" content="ABC Engineering About page" />
          <meta property="og:image" content="https://abc-eng.com/logo.webp" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://abc-eng.com/about" />
        </Helmet>
            <section className="part1about">
            <div className="title">
                <div className="title-inner">
                <div className="lets">
                    <div className="lets-inner"><span>Let's</span></div>
                </div>
                <div className="share">
                    <div className="share-inner"><span>Share</span></div>
                </div>
                <div className="succ">
                    <div className="succ-inner"><span>Success</span></div>
                </div>
                </div>
            </div>

            <div className="image">
                <img src={require("./images/bg.webp")} alt='office'/>
            </div>
            </section>
            <section className="part2about">
                <div className="part2-div col-md-4" data-aos="fade-right" data-aos-delay="50">
                    <div className="part2-in">
                        <h2 style={{color:"black"}}>Mission</h2><br/>
                        <h4><IoIosArrowDroprightCircle style={{color:"#0066FF"}} />ABC Engineering S.A.E supports its customers and helps them to focus on their business while we take care of their critical systems. We help our customers to:</h4><ul>
                        <li>Achieve best OPEX while having best performance.</li>
                        <li>Maximize the uptime of their critical equipment by providing required maintenance.</li>
                        <li>Increase data center efficiency by suggesting solutions.</li>
                        </ul>
                    </div>
                </div>
                <div className="part2-div col-md-4 offset-md-4" data-aos="fade-left" data-aos-delay="150">
                    <div className="part2-in">
                        <h2 style={{color:"black"}}>Vision</h2><br/>
                        <h4 style={{paddingRight:"3rem"}}><IoIosArrowDroprightCircle style={{color:"#0066FF"}} />Our ultimate goal is to become the go-to provider of top-notch electromechanical services for data centers in Egypt. 
                        We aspire to offer a comprehensive range of high-quality solutions that exceed our clients' expectations in terms of reliability, efficiency, and effectiveness. 
                        By utilizing the latest technologies and industry best practices, we aim to deliver unparalleled service that ensures our clients' data centers operate smoothly and efficiently around the clock. 
                        We strive to establish long-lasting partnerships with our clients by providing exceptional customer service, timely support, and customized solutions that cater to their specific needs. 
                        Through our commitment to excellence, innovation, and customer satisfaction, we endeavor to become the most trusted and respected electromechanical service provider for data centers in Egypt.</h4> 
                    </div>

                </div>
                <div className="part2-div col-md-4" data-aos="fade-right" data-aos-delay="250">
                    <div className="part2-in">
                        <h2 style={{color:"black"}}>Values</h2><br/>
                        <h4><IoIosArrowDroprightCircle style={{color:"#0066FF"}}/>ABC Engineering S.A.E is committed to the following core values:</h4><ul>
                        <li>Customer Satisfaction: We strive to make our customer satisfied by providing on time and up to date service. </li>
                        <li>Effective Communication: Our teams are committed to provide to and receive from customer all necessary  information for effective communication</li>
                        <li>Integrity & Ethical Behavior: Our teams are committed to ethical behavior, our company code of conduct has strict rules to fight unethical and corrupted behaviors.</li> 
                        <li>Strive to excellence: through continuous training and  monitoring through KPIs to measure our performance.</li>
                        </ul>
                    </div>
                </div>
            </section>

        </div>
    );
  }
}
export default About;